@use "../../utils/styles/colors.scss" as colors;
@use "../../utils/styles/common.scss" as basebtn;

.card {
  box-shadow: 0px 0px 4px colors.$shadow-base;
  border-radius: 5px;
  min-width: 245px;
  max-width: 245px;
  width: max-content;
  padding: 14px 24px;
  margin: 0px 14px;
  display: flex;
  flex-direction: column;
  justify-content: unset;
  background-color: white;
  background-size: auto;
  background-repeat: no-repeat;
  background-blend-mode: multiply ;
  color: colors.$text-card;
  margin-bottom: 14px !important;
  height: 150px;
  &-image {
    display: flex;
    justify-content: unset;
    position: relative;
    span {
      color: colors.$text-title-card;
      font-size: 100px;
    }
    .progress {
      position: absolute;
      font-size: 40px;
      color: #1cd1b6;
      bottom: 0;
      right: -5px;
    }
    .material-icons {
      color: #1cd1b6;
      font-size: 100px;
      margin-right: 60px;
      margin-top: -20px;
    }
  }
  &:first-child {
    margin-right: 14px !important;
  }

  h5 {
    font-style: normal;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 16px;
    position: relative;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    padding-bottom: 5px;
    border-bottom: 0.5px solid colors.$color-secundary;
    color: colors.$color-secundary;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 16px;
    width: 100%;
    margin-top: -20px;
    color: colors.$color-secundary;
  }
  &:hover {
    background-color: colors.$hover-card-base;
    color: colors.$text-base;
    cursor: pointer;
    transform: scale(1.01);
    transition: all 0.25s;
    h5{
      color: colors.$text-base;
      border-bottom: 0.5px solid colors.$text-base;
    }
    p{
      color: colors.$text-base;
     
    }
    &-image {
      span {
        color: colors.$text-base;
        transition: all 0.2s;
      }
    }
  }
  &-selected {
    background-color: #00b398;
    color: white;
    transform: scale(1.05);
    transition: all 0.2s;
    h5{
      color: white;
    }

    .card-image {
      span {
        color: white !important;
        transition: all 0.2s;
      }
    }

    h5 {
      border-bottom: 0.5px solid rgba(229, 229, 229, 0.3);
    }
  }
  &-blocked {
    background-color: #c2c2c2;
    color: white;
    cursor: pointer;
    transition: all 0.2s;
    h5{
      color: white;
    }
    .card-image {
      span {
        color: white;
        transition: all 0.2s;
      }
    }

    h5 {
      border-bottom: 0.5px solid rgba(229, 229, 229, 0.3);
    }
  }
  &-progress {
    background-color: #B2E4DA;
    color: #1cd1b6;
    cursor: pointer;
    transition: all 0.2s;
    h5{
      color: #1cd1b6;
    }
    .card-image {
      span {
        color: #1cd1b6;
        transition: all 0.2s;
      }
    }
    h5 {
      border-bottom: 0.5px solid  #1cd1b6;;
    }
  }
}
.card-title {
  box-shadow: 0px 0px 4px colors.$shadow-base;
  border-radius: 5px;
  min-width: 180px;
  max-width: max-content;
  width: max-content;
  padding: 14px 24px;
  margin: 0px 14px;
  display: flex;
  flex-direction: column;
  justify-content: unset;
  background-size: auto;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  color: colors.$text-card;
  height: 45px;

  h5 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: relative;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    padding-bottom: 5px;
    border-bottom: 0.5px solid colors.$border-color-base;
    svg {
      margin-right: 15px;
    }
  }
  &:hover {
    background-color: colors.$hover-card-base;
    color: colors.$text-base;
    cursor: pointer;
    transform: scale(1.05);
    transition: all 0.2s;
  }
  &-selected {
    background-color: #00b398;
    color: white;
    transform: scale(1.05);
    transition: all 0.2s;

    h5 {
      border-bottom: 0.5px solid rgba(229, 229, 229, 0.3);
    }
  }

  &-blocked {
    background-color: #c2c2c2;
    color: white;
    cursor: not-allowed;
    transform: scale(1.05);
    transition: all 0.2s;
    pointer-events: none;

    h5 {
      margin: 0;
      border-bottom: 0.5px solid rgba(229, 229, 229, 0.3);
    }
  }
}
.mdfResource{
  justify-content: start; 
}


.options-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 14px 0px;
  align-items: center;

  &__card{
    box-shadow: 0px 0px 4px colors.$shadow-base;
    border-radius: 5px;
    min-width: 180px;
    max-width: max-content;
    width: max-content;
    padding: 14px 24px;
    margin: 0px 14px;
    display: flex;
    flex-direction: column;
    justify-content: unset;
    background-size: auto;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    color: colors.$text-card;
    height: 45px;
    transition: all ease-in-out .5s;
  
    h5 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;
      position: relative;
      margin-top: 0.5rem;
      margin-bottom: 2rem;
      padding-bottom: 5px;
      border-bottom: 0.5px solid colors.$border-color-base;
      svg {
        margin-right: 15px;
      }
    }
    &:hover {
      background-color: colors.$hover-card-base;
      color: colors.$text-base;
      cursor: pointer;
      transform: scale(1.05);
      transition: all 0.2s;
    }
    &-selected {
      background-color: #c2c2c2 !important;
      color: white;
      transform: scale(1.05);
      transition: all 0.2s;
  
      h5 {
        border-bottom: 0.5px solid rgba(229, 229, 229, 0.3);
      }
    }
  
    &-blocked {
      background-color: #c2c2c2;
      color: white;
      cursor: not-allowed;
      transform: scale(1.05);
      transition: all 0.2s;
      pointer-events: none;
  
      h5 {
        margin: 0;
        border-bottom: 0.5px solid rgba(229, 229, 229, 0.3);
      }
    }
  }
  button {
      @include basebtn.buttonBase;
     
      margin-right: 14px;
      svg {
          pointer-events: none;
          margin-right: 7.5px !important;
      }
      span{
        display: flex;
        align-items: center;
      }
  }
}
.btnClose{
  width: 32px !important;
  height: 32px !important;
  border-radius: 50% !important;
  align-items:center !important;
  svg{
    margin-left: 8px;
  }
}
