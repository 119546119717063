@use "../../utils/styles/colors.scss" as colors;
@use "../../utils/styles/fonts.scss" as fonts;
@use "../../utils/styles/common.scss" as basebtn;

.open-PopUp{
    font-family: fonts.$museo-sans-regular !important;
    width: 100vw;
    height:100vh;
    display: flex;
    background:#51534a36;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3000;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    
}

.close-PopUp{
    display: none;
    top: -100vw;
    left: -100vw;
    transition: all .25s ease-in-out;
}

.wide{
    display: flex;
    flex-direction: column;
    min-width: 70vw !important;
    max-height: 90vh;
    padding: 30px !important;
}

.wide2{
    width: max-content !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 96px !important;
}
.mdf4{
    min-width: 70vw !important;
}

.wide3{
    min-width: 70vw !important;
    padding: 14px 32px !important;
    input{
        width: 60%;
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        padding: 0px 12px;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        box-sizing: border-box;
        text-align: center; 
    }::placeholder {
        text-align: center; 
        text-transform: uppercase;
        font-size: 10px;
     }
}
.center{
    span{
        padding-top: 15px;   
        color: #51534A;
    }
    text-align: center !important;
    letter-spacing: 1px;
    text-transform: uppercase;
    
}



.popUp-Content{
    width: max-content;
    min-width: 40vw;
    max-width: 58vw;
    height: max-content;
    background-color: white;
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

    &__TC{
        height: 80vh;
        min-height: 65vh;
        max-height: 80vh;

        text-align: left;
        &_content{
            overflow-y: scroll;
            height: 80%;
            padding-right: 10px;
            h4{
                font-size: 16px;
                letter-spacing: .15px;
            }

            p{
                font-size: .875rem;
                letter-spacing: .5px;
            }
        }
    }
    
   &__Close{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: sticky;
    top: 0;
    background-color: colors.$background-header;

    svg{
        color: #51534a60;
        &:hover{
                cursor: pointer;
                transform: scale(1.05);
                color: #51534A;
            }
        }   
   }

   &__title{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    border-bottom: 0.25px solid #51534a36;  
    color: #54C6B5;
    position: sticky;
    top: 24px;
    background-color: colors.$background-header;
    h3{
        margin: 0 0;
        text-align: start;
        padding: 7.5px 0px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
   }

   &__form{
        padding: 15px 30px;
        color: #51534a95;
      &_field{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;

        label{
            width: 40%;
            min-width: 30%;
            max-width: 40%;
            text-transform: capitalize;
            letter-spacing: 1px;
            font-size: 1rem;
            font-weight: bold;
            padding-right: 15px;
            color: colors.$text-color-title;
            
        }   
        p{
            margin: 0 0;
        }
        .MuiFormControl-root{
            width: 100% !important;
            input, select{
                width: 100%;
                height: 40px;
                font-size: 14px;
                font-weight: 400;
                padding: 0px 12px;
                background: #FFFFFF;
                border: 1px solid rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                box-sizing: border-box;
                font-family: fonts.$museo-sans-regular !important;
            }
        }

        &-labelFile{
            width: 100% !important;
            max-width: 100% !important;
            border: 1px solid #51534a4b;
            color: #51534a8a;
            padding: 15px 0px;
            border-radius: 5px;
            cursor: none;  
            span{
           
                font-size: 14px;
                padding: 15px ;
                font-weight: normal;
                font-size: 14px;
                color: white;
                background-color: #51534a4b;
                margin-right: 15px;
                cursor: pointer;
                background-color: #00b39894;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                &:hover{
                    transform: scale(1.05);
                    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
                    transition:all .2s ease-in-out ;
                    background-color: #00b398;
                }
               
            }  
            input[type="file"]{
                display: none;
               
              
             }
        }

        textarea{
            width: 100%;
            height: 8vh;
            resize: none;
            font-weight: 400;
            background: #FFFFFF;
            padding: 12px;
            border-radius: 5px;
            box-sizing: border-box;
        }

        input[type='file']{
           width: 100%;
           border-radius: 5px;
           border: 1px solid black;
           box-sizing: border-box;
           border: 1px solid rgba(0, 0, 0, 0.25);
           background: #FFFFFF;
           padding: 10px 12px;
           font-family: fonts.$museo-sans-regular !important;
        }
      }
      
   }

   &__actions{
    display: flex;
    flex-direction: row;
    margin: 0px 0px;
    justify-content: flex-end;
    border-top: 0.25px solid #51534a36;  
    padding: 14px 0px;

    button{
        @include basebtn.buttonBase;
        
    }
    button:last-child{
        margin-left: 15px !important;
    }
    
    .btn_OutLine{
        color: colors.$color-principal;
        background: colors.$text-base;
        border: 1px solid colors.$color-principal;
        &:hover{
            color: colors.$text-base;
            background:colors.$color-principal;
            border: 1px solid colors.$text-base;
         }
    }
    
   }

   &__Permissions{
    max-height: 50vh;
    overflow-y: auto; 
    width: 56vw;
  
    ul{
        list-style: none;
    }
    input{
        margin-right: 10px;
        &:checked{
            accent-color: #00B398;
            border: 1px solid white;
        }
        &:hover{
            background-color: red;
        }
    }

    &_title1{
        padding: 3px 0px;
        letter-spacing: 0px;
        align-items: baseline;
        display: flex;
        font-weight: 600;
        font-size: 1rem;
       
        span{
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            margin-left: 5px;
            color: #51534A;
        }
       
    }
    &_title2{
        padding: 3px 0px;
        letter-spacing: 0px;
        font-size: 14px;
        text-transform: uppercase;
       
        span{
            font-size: 10px;
            letter-spacing: 0.5px;
            margin-left: 5px;
            color: #51534A;
        }
    }
   }

}

.resource-Content{
    height: 80vh !important;
    width: 90vw !important;
    overflow: auto;

    &__view{
        width:100%; 
        height: 100%;
    }
}

.css-1anqmj6-MuiPopper-root-MuiPickersPopper-root{
    z-index: 5000 !important;
}

.css-1mtsuo7{
    z-index: 5000 !important;
}