@use "../../utils/styles/colors.scss" as colors;
@use "../../utils/styles/common.scss" as basebtn;
@use "../../utils/styles/fonts.scss" as fonts;

.open-PopUpAlert{
    width: 100vw;
    height:100vh;
    display: flex;
    background:colors.$color-secundary;;
    background:#51534a36;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    justify-content: center;
    align-items: center;   
}
.close-PopUpAlert{
    display: none;
    top: -100vw;
    left: -100vw;
    transition: all .25s ease-in-out;
}

.popUpAlert-Content{
    width: max-content;
    max-width: 1000px;
    height: max-content;
    background-color: colors.$text-btn-active;
    padding: 30px;
    border-radius: 5px;
    box-shadow: colors.$shadow-container;
    font-family: fonts.$museo-sans-regular;
    &__contentMessage{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 0px 14px;
        margin-bottom: 15px;
        p{
            padding-left: 14px;
            color: colors.$text-card ;
        }
        &_iconandmessage{
            display: flex;
            justify-content: space-around;
            align-items: center;
        }
        &__error{
            width: 100%;
            display: flex;
            align-items:center;
            justify-content: center;
            flex-direction: column;
            button{
                @include basebtn.buttonBase;
            }
        }
    }


   &__Close{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    
    
   
    span{
        color: colors.$text-card;
        &:hover{
                cursor: pointer;
                transform: scale(1.05);
                color: colors.$color-icon-menu;
            }
        
    }
    
   }
   &__title{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    border-bottom: 0.25px solid colors.$color-secundary;  
    color:colors.$color-principal;
    h3{
        margin: 0 0;
        text-align: start;
        padding: 7.5px 0px;
        letter-spacing: 0.15rem;
        text-transform: uppercase;
    }
   }

   &__actions{
    display: flex;
    flex-direction: row;
    margin: 0px 0px;
    justify-content: center;
    border-top: 0.25px solid colors.$text-card;  
    padding: 14px 0px;
    button{
        width: 100%;
        margin-left: 0 !important;
        @include basebtn.buttonBase;
    }
   }

   &__Permissions{
    ul{
        list-style: none;
    }
    input{
        margin-right: 10px;
        &:checked{
            accent-color: #00B398;
            border: 1px solid white;
        }
        &:hover{
            background-color: red;
        }
    }

    &_title1{
        padding: 3px 0px;
        letter-spacing: 0px;
        align-items: baseline;
        display: flex;
        font-weight: 500;
        font-size: 16px;
       
        span{
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            margin-left: 5px;
            color: #51534A;
        }
       
    }
    &_title2{
        padding: 3px 0px;
        letter-spacing: 0px;
        font-size: 14px;
        text-transform: uppercase;
       
        span{
            font-size: 10px;
            letter-spacing: 0.5px;
            margin-left: 5px;
            color: #51534A;
        }
    }
   }

}