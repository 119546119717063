@use "../../utils/styles/colors.scss" as colors;
@use "../../utils/styles/fonts.scss" as fonts;



.table-container {
    width: 99%;
    height: max-content;
    box-shadow: colors.$shadow-container;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 14px 0px;
    padding: 14px 7.5px;


    &__searchTable{
        display: flex;
        flex-direction: row;
        align-items: baseline;
        margin-bottom: 14px;
        .MuiInputBase-root{
            font-family: fonts.$museo-sans-regular !important;

        }
        .ForwardRef-iconButton-2{
            color: colors.$color-secundary !important;
        }
    }

    table{
        
        th{
            font-family: fonts.$museo-sans-regular !important;
            font-weight: bold !important;
            text-align: center;        
            color: colors.$text-color-black;
            background-color: #e0e0e0;
            letter-spacing: .5px;
        }
        td{
            font-family: fonts.$museo-sans-regular !important;
            text-align: center;
            color: colors.$color-secundary;
            text-transform: capitalize !important;
            font-size: .875rem !important;
            padding: 14px !important;
            select{
                width: 80%;
                height: 30px;
                font-size: .875rem;
                padding: 0px 12px;
                color: colors.$color-secundary;
                border-radius: 5px;
                box-sizing: border-box;  
                text-transform: capitalize !important;   
            }
            option{
                text-transform: capitalize !important;
            }
        }
    }
    .action-content{
        display: flex;
        justify-content: center;
        button{
            background: colors.$color-principal;
            border: 1px none;
            border-radius: 5px;
            font-weight: 400;
            line-height: 18px;
            width: 35px;
            height:35px;
            color: white;
            cursor: pointer;
            transition: all ease-in-out 0.2s;
            svg{
                font-size: 1.25rem !important;
            }
            &:hover{
               color:colors.$color-principal;
               background: rgb(255, 255, 255);
               border: 1px solid colors.$color-principal;
            }&:hover:disabled{
                background: #00b398b9;
            }&:disabled{
                pointer-events: none;
                opacity: .5;
            } 
            
        }
    }
}
.containerDot{
    display: flex;
    align-items: center;
    justify-content: center;

    .dot{
        height:16px;
        width:16px; 
        border-radius:50%;
        display:'inline-block';
        margin-left: 5px;
        margin-right: 5px;
        &__green{
            background-color:colors.$color-sucess;
        }
        &__red{
            background-color:colors.$color-warning;
        }

    }
}