@use "../../utils/styles/colors.scss" as colors;

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0px;
  text-transform: uppercase;
  font-size: 14px;
  //background: rgba(255, 255, 255, 0.4);
  background: #00b398;
  border: 1px none;
  border-radius: 5px;
  font-weight: 400;
  line-height: 18px;
  width: 240px;
  height: 40px;
  color: white;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  padding: 7.5px;
  &:hover {
    color: #00b398;
    background: rgb(255, 255, 255);
    border: 1px solid #00b398;
  }
  &:hover:disabled {
    background: #00b398b9;
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.outline {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0px;
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 5px;
  font-weight: 400;
  line-height: 18px;
  width: 240px;
  height: 40px;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  padding: 7.5px;
  color: colors.$color-principal;
  background: colors.$text-base;
  border: 1px solid colors.$color-principal;
  &:hover{
      color: colors.$text-base;
      background:colors.$color-principal;
      border: 1px solid colors.$text-base;
   }
}
