.stadistictemplate{
    width: 100% !important;
    -moz-box-shadow:    inset 0 0 4px #51534a;
    -webkit-box-shadow: inset 0 0 4px #51534a;
    box-shadow:         inset 0 0 4px #51534a;
    border-radius: 5px;
    padding: 15px;
    font-size: 14px;
    background-color: #b3b3b324;

    &__stadistic{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        align-items: center;
        margin-bottom: 15px;
        color: #51534acd;
        &_name{
            display: flex;
            align-items: center;
            font-weight: bold;
        }
        span{
            text-transform: uppercase;
            margin-left: 8px;
        }
        &_resultS{
            font-weight: bold;
            padding-right: 15px;
        }
    }
    &__stadistic:last-child{
        margin-bottom: 0px;
    }
    

}