body {
  margin: 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
} 

#root{
  display: flex;
  align-content: center;
  justify-content: center;
  margin: 0 !important;
}
