@use "./colors.scss" as colors;
@use "./fonts.scss" as fonts;

@mixin buttonBase{
    font-family: fonts.$museo-sans-regular !important;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0px;
    text-transform: uppercase;
    font-size: .875rem;
    background: colors.$color-principal;
    border: 1px none;
    border-radius: 5px;
    font-weight: 700;
    line-height: 18px;
    width: 240px;
    height:40px;
    color: colors.$text-base;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
    padding: 7.5px;
    &:hover{
       color: colors.$color-principal;
       background: colors.$text-btn-active;
       border: 1px solid colors.$color-principal;
    }&:hover:disabled{
        background: colors.$color-btn-hover;
    }&:disabled{
        pointer-events: none;
        opacity: .5;
    } 
}
