@use "../../utils/styles/common.scss" as basebtn;
@use "../../utils/styles/colors.scss" as colors;

.title-content {
    position: relative;
    padding: 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    h1{
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: colors.$color-secundary;
        padding: 7px 0px;
        svg {
            margin-right: 14px !important;
        }
    }

    
    &__Options {
        display: flex;
        flex-direction: row;
        width: max-content;
        justify-content: space-between;

        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            display: flex;
            align-items: center;
            color:colors.$color-secundary;
        }
        &_goback {
            padding: 5px 0px;
            background: rgba(255, 255, 255, 0.4);
            border: 1px solid colors.$color-principal;
            border-radius: 5px;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            width: 240px;
            height: 40px;
            color: colors.$color-principal;
            cursor: pointer;
            transition: all ease-in-out 0.2s;
            &:hover {
                background-color:colors.$color-principal;
                color: rgb(255, 255, 255);
            }
        }
        button{
            @include basebtn.buttonBase;
            margin-right: 0px !important;
        }
        &_download {
            margin-right: 14px;
             :hover {
                background-color: colors.$color-principal;
                color: rgb(255, 255, 255);
            }
            svg {
                pointer-events: none;
                text-transform: none !important;
                margin-right: 8px;
            }
        }
    }
    &::after {
         content: "";
         width: 100%;
         height: 1px;
         background-color:colors.$color-secundary;
         position: absolute;
         bottom: 10px;
         border-radius: 5px;
        
    }
    h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: colors.$color-secundary;
        span {
            margin-right: 15px;
        }
        svg {
            margin-right: 14px !important;
        }
    }
    h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 14px;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        color: colors.$color-secundary;
        span {
            margin-right: 15px;
        }
    }
    h4 {
        font-style: normal;
        font-weight: 600;
        font-size:.875rem;
        line-height: 14px;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        color: colors.$color-secundary;
    }
}

.mdf3 {
    margin-top: 12px !important;
    &::after{
        bottom: 10px !important;
        height: 1px !important;

    }
}