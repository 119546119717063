@use "../../utils/styles/colors.scss" as colors;

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Title {
  color: colors.$text-color-title-menu;
}

.Paragraph {
  width: 60%;
  text-align: center;
  color: colors.$text-color-title-menu;
}

.Content {
  width: 60%;
  text-align: center;
}

.Profiles {
  width: 60%;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  place-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.Profile {
  width: calc(30% - 30px);
  min-height: 230px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 20px;
  min-width: 180px;
  cursor: pointer;
  background-color: colors.$card-background-gray;
  color: colors.$text-color-title-menu;
  transition: background-color 1s, color 1s, box-shadow 1s;
}

.Profile:hover {
  background-color: colors.$color-principal;
  box-shadow: 0px 2px 10px 0px rgba(107, 107, 107, 1);

  .ProfileTitle {
    color: colors.$text-base;
  }

  .ProfileDescription {
    color: colors.$text-base;
  }
}

.ProfileTitle {
  margin: 0 auto;
}

.ProfileDescription {
}

.Selected {
  background-color: colors.$color-principal;
  color: colors.$text-base;
  box-shadow: 0px 2px 10px 0px rgba(107, 107, 107, 1);
}

.Profile:active {
  transform: scale(1.1);
  transition: transform 0.5s;
}

.Actions {
  width: 60%;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  place-content: end;
}
