//Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');



@font-face{
    font-family: 'Museo Regular';
    src: url('../../assets/fonts/Museo300-Regular.otf');
}


// Predefinida
@font-face{
    font-family: 'Museo Sans Regular';
    src: url('../../assets/fonts/MuseoSans-300.otf');
}

$roboto-regular: 'Roboto';
$museo-regular: 'Museo Regular';
$museo-sans-regular: 'Museo Sans Regular';
