.preview {
    height: max-content;
    max-height: 350px;
    color: #00B398;
    margin: 7.5px 0px;
    border-top: 0.25px solid #51534a36;
    overflow-y: scroll;
    padding: 0px 14px;
    &-title {
        margin: 11px 0;
        font-size: 18px;
        font-weight: 700;
        width: 100%;
        border-bottom: 1px solid #51534a36;
    }
    &-topic {
        margin: 10px 0;
        font-weight: 0;
        font-size: 15px;
    }
    &-question {
       margin-top: 0px;
       margin-bottom: 9px;
       color: #51534acd;
       font-size: 12px;
    }
    &-answer {
        font-size: 13px;
        font-weight: 400;
        padding-left: 2px;
        margin: 0 !important;
        color: #51534adb;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
       span{
        width: 10%;
        text-align: end;
       }
        
        &-cal {
            color: #03917b;
            font-weight: bold;
            font-size: 14px;
        }
    }
}

.mdf2{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 7px 14px;
    svg{
        margin-right: 5px;
    }
}
.mdf3{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 7px 0px;
    svg{
        margin-right: 5px;
    }
    h5{
        margin-top: 3px !important;
    }
}

.actions2{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    button{
        margin: 0px 14px !important;
    }:last-child{
        margin-right: 0px !important;
    }
    &-column1{
        width: 25%;
        h3{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 16px;
            display: flex;
            align-items: center;
            letter-spacing: 2px;
            color: #51534a7e;  
            text-transform: uppercase;
        }
    }

    &-column2{
        width: 80%;
        flex-direction: row;
        display: flex;
        justify-content: flex-end;
    }
}