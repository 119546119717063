@use "../../utils/styles/fonts.scss" as fonts;
@use "../../utils/styles/colors.scss" as colors;

.Container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Title {
  font-weight: bold;
}

.Paragraph {
  margin-bottom: 50px;
  text-align: center;
}

.FilterSection {
  width: 60%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  place-content: center;
  gap: 10px;
}

.Autocomplete {
  min-width: 30%;
  max-width: 50%;
}

.ContentSection {
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Partners {
  flex: 1 1 0%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  place-content: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.Partner {
  height: 200px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: flex-star;
  padding: 20px;
  flex: 1 1 20%;
  box-sizing: border-box;
  max-width: 20%;
}

.IconNext {
  color: colors.$color-principal;
}

.Icon {
  cursor: pointer;
  margin-bottom: 5px;
  animation: pulseOut 1s ease-in-out;
}

.IconSelected {
  cursor: pointer;
  margin-bottom: 5px;
  animation: pulseIn 1s ease-in-out;
}

@keyframes pulseIn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulseOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.Image {
  width: auto;
  height: 70PX;
  margin-bottom: 20px;
  max-width: 100%;
  max-height: 70PX;
}

.PartnerTitle {
  text-align: center;
  margin: 0;
  font-family: fonts.$museo-sans-regular;
  color: colors.$text-color-title-menu;
}

.Pagination {
  font-family: fonts.$museo-sans-regular;
  color: colors.$text-color-title-menu;
}

.ActionsSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
